'use client'

import { PREFETCH_DISABLE_LIST } from 'config/preload'
import Link, { LinkProps } from 'next/link'
import { useParams } from 'next/navigation'
import React, { FC, HTMLProps } from 'react'
import { getSafeLocale, injectRegionIntoPath, RegionShort } from 'utils/internationalization'

type LinkWithLocaleProps = Omit<HTMLProps<HTMLAnchorElement>, 'href'> & LinkProps

export const LinkWithLocale: FC<LinkWithLocaleProps> = (props) => {
	const params = useParams()
	const locale = getSafeLocale(params)
	const path = injectRegionIntoPath(props.href, locale as RegionShort)
	const href = path ? path.toString() : '/'

	return (
		<Link
			{...props}
			href={path}
			locale={false}
			prefetch={PREFETCH_DISABLE_LIST.includes(href) ? false : undefined}
		>
			{props.children}
		</Link>
	)
}

export default LinkWithLocale

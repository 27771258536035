'use client'

import { ButtonComponent, ButtonInteriorProps } from './types'
import { FC, MouseEvent, useRef } from 'react'
import { formatLink } from 'utils'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import { A } from '../../basic/A'
import cx from 'classnames'
import styles from './Button.module.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { amClickedButton } from 'events/amplitude'
import { ButtonLoadingSpinner } from './ButtonLoadingSpinner'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

const ButtonContent: FC<ButtonInteriorProps> = (props) => {
	return (
		<>
			<span
				style={{ opacity: props.loading ? 0 : 1 }}
				className={props.empty ? '' : styles.button_span}
			>
				{props.children}
			</span>
			{props.loading && <ButtonLoadingSpinner color={props.spinnerColor} />}
		</>
	)
}

const ButtonBase = (props: ButtonInteriorProps) => {
	const ref = useRef(null)
	const href = typeof props.href === 'string' ? formatLink(props.href) : props.href

	useAnimateInOnScroll(ref, { ...props.animationSettings, disable: !props.animateOnScroll })

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		if (href) {
			const linkID = props.id || 'unknown_id'
			amClickedButton(href.toString(), linkID, props.trackingData)
		} else {
			amClickedButton(props.title || '', props.id, props.trackingData)
		}

		if (props.onClick) {
			if (href === '#') e.preventDefault()
			props.onClick(e)
		}
	}

	const commonProps = {
		id: props.id,
		className: cx(styles.base, props.injectedClass, props.className),
		role: props.role || (href ? 'link' : 'button'),
		'aria-label': props.ariaLabel,
		'aria-haspopup': props.ariaHasPopup,
		'aria-expanded': props.ariaExpanded,
		'aria-controls': props.ariaControls,
		style: props.style,
	}

	const commonLinkProps = {
		...commonProps,
		target: props.target,
		rel: props.target === '_blank' ? 'noopener' : undefined,
		onClick: handleClick,
	}

	if (!href) {
		return (
			<button
				ref={ref}
				{...commonProps}
				onClick={handleClick}
				disabled={props.disabled || props.loading}
				title={props.title}
				type={props.type || 'button'}
			>
				<ButtonContent {...props} />
			</button>
		)
	}

	if (href.toString().includes('#') && !props.disableSmoothScroll) {
		return (
			<AnchorLink
				ref={ref}
				{...commonLinkProps}
				href={href.toString()}
			>
				<ButtonContent {...props} />
			</AnchorLink>
		)
	}

	if (props.aElement) {
		return (
			<A
				{...commonLinkProps}
				href={href}
			>
				<ButtonContent {...props} />
			</A>
		)
	}

	return (
		<Link
			href={href}
			ref={ref}
			{...commonLinkProps}
		>
			<ButtonContent {...props} />
		</Link>
	)
}

ButtonBase.displayName = 'Button'

export const Button = Object.assign(ButtonBase, {}) as unknown as ButtonComponent
